
import { Component, Vue, Prop } from "vue-property-decorator";
import { IMortgageStatus, IMortgageStep } from "@/models/Mortgages";
import HiboStepsList from "@/components/ui/HiboStepsList.vue";

@Component({ components: { HiboStepsList } })
export default class MortgagesStatus extends Vue {
  @Prop({ type: Array, required: true })
  protected steps!: IMortgageStep[];

  @Prop({ type: Object, required: true })
  protected selectedMortgageStatus!: IMortgageStatus;
}
