
import { Component, Vue, Prop } from "vue-property-decorator";
import { IMortgageStatus, IMortgageStep } from "@/models/Mortgages";
import dayjs from "dayjs";
import {
  API_MORTGAGES_STATUS_KEY,
  API_MORTGAGES_STATUS_LABEL,
} from "@/constants/mortgages";

@Component
export default class HiboStepsList extends Vue {
  @Prop({ type: Array, required: true })
  protected steps!: IMortgageStep[];

  @Prop({ type: Boolean, default: false })
  protected numbered?: boolean;

  @Prop({ type: Object, required: true })
  protected selectedMortgageStatus!: IMortgageStatus;

  protected isCurrentStep(index: number): boolean {
    return index === this.steps.findIndex((step) => !step.completed);
  }

  protected isLastStep(index: number): boolean {
    return index === this.steps.length - 1;
  }

  protected isNextToLastStep(index: number): boolean {
    return index === this.steps.length - 2;
  }

  protected getDate(date: string): string {
    const dateFormat: string = this.$t("dateFormat").toString();
    return dayjs(date).format(dateFormat);
  }

  protected getDateAndTime(date: string): string {
    const dateFormatAndTime: string = this.$t("dateFormatAndTime").toString();
    return dayjs(date).format(dateFormatAndTime);
  }

  protected lostTitle(step: IMortgageStep): string {
    return step.id === "mortgage_lead_status_1" ? "Perdida" : "Rechazada";
  }

  protected getStepLabel(index: number, step: IMortgageStep): string {
    if (this.isCurrentStep(index)) {
      if (
        this.selectedMortgageStatus.status ===
        API_MORTGAGES_STATUS_KEY.POSTPONED
      )
        return API_MORTGAGES_STATUS_LABEL.POSTPONED;
      else if (
        this.selectedMortgageStatus.status ===
        API_MORTGAGES_STATUS_KEY.PENDING_PROPERTY
      )
        return API_MORTGAGES_STATUS_LABEL.PENDING_PROPERTY;
    }

    return step.label;
  }
}
