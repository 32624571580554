
import { Component, Vue, Prop, Emit, Inject } from "vue-property-decorator";
import FileUploader from "@/components/widgets/FileUploader.vue";
import { IDocument } from "@/models/Documents";
import { BUSINESS } from "@/constants";

@Component({
  components: { FileUploader },
})
export default class HiboFileUploaderModal extends Vue {
  @Inject() readonly curr_business!: string;

  @Prop({ type: Number, required: true })
  protected operationId!: number;

  @Prop({ type: Object, required: true })
  protected docGroup!: IDocument;

  @Prop({ type: String, default: "" })
  protected uploadUrl?: string;

  @Prop({ type: String, default: "" })
  protected customTitle?: string;

  @Prop({ type: String, default: null })
  protected introText?: string;

  @Prop({ type: String, default: null })
  protected importantInfo?: string;

  protected showFileUploader = false;
  protected isMortgage = this.curr_business === BUSINESS.mortgages;

  mounted() {
    this.showFileUploader = !this.docGroup.files.length;
  }

  @Emit("close") close() {}
  @Emit("uploaded") uploaded() {}
}
