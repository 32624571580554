// Dayjs
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/es";
import "dayjs/locale/it";
import "dayjs/locale/pt";
import calendar from "dayjs/plugin/calendar";
import updateLocale from "dayjs/plugin/updateLocale";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isBetween from "dayjs/plugin/isBetween";
import weekOfYear from "dayjs/plugin/weekOfYear";

dayjs.extend(utc);
dayjs.extend(calendar);
dayjs.extend(updateLocale);

dayjs.extend(LocalizedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(weekOfYear);
dayjs.locale("es"); // initial locale

// TODO Translate
dayjs.updateLocale("es", {
  calendar: {
    sameDay: "[Hoy] ", // The same day ( Today )
    nextDay: "[Mañana]", // The next day ( Tomorrow )
    nextWeek: "dddd DD MMMM", // The next week ( Sunday )
    lastDay: "dddd DD MMMM", // The day before ( Yesterday )
    lastWeek: "dddd DD MMMM", // Last week ( Last Monday )
    sameElse: "dddd DD MMMM", // Everything else ( 7/10/2011 )
  },
});

export default dayjs;

export const setLocale = (_locale = "es") => {
  dayjs.locale(_locale);
};
