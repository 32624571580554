<script>
// TODO - Pasar a TypeScript usando @types/dropzone u otra libreria

import { Component, Vue, Prop } from "vue-property-decorator";
import HiboQuote from "@/components/ui/HiboQuote.vue";
import Dropzone from "vue2-dropzone";
import * as Sentry from "@sentry/browser";

@Component({
  components: { Dropzone, HiboQuote },
})
export default class FileUploader extends Vue {
  @Prop({ type: String, required: true })
  uploadUrl;

  @Prop({ type: Function, default: null })
  onUpload;

  @Prop({ type: Array, default: ["image"] })
  mimeTypes;

  loading = false;
  uploadDisabled = true;
  over = false;
  errors = [];
  mimesMessage = "";

  getMimeTypes() {
    let mimes = "";
    if (this.mimeTypes.includes("image")) {
      mimes += "image/png,image/jpg,image/jpeg,";
      this.mimesMessage += ".png, .jpg, .jpeg";
    }
    if (this.mimeTypes.includes("pdf")) {
      mimes += "application/pdf,";
      if (this.mimesMessage !== "") this.mimesMessage += ", ";
      this.mimesMessage += ".pdf";
    }
    return mimes;
  }

  get dropzoneOptions() {
    return {
      url: this.uploadUrl,
      thumbnailWidth: 150,
      thumbnailHeight: 150,
      maxFilesize: 5, // Mb
      acceptedFiles: this.getMimeTypes(),
      maxFiles: 10,
      parallelUploads: 1,
      autoProcessQueue: false,
      timeout: 0,
      // capture: 'camera',
      paramName: "fileToUpload",
      previewTemplate: `
        <div class="dz-preview dz-file-preview">
          <div class="dz-details flex items-center">
              <div class="text-sm dz-filename"><span data-dz-name></span></div><div class="dz-remove-mark"><img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMjIuODggMjIuODgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDIyLjg4IDIyLjg4OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8cGF0aCBzdHlsZT0iZmlsbDojMUUyMDFEOyIgZD0iTTAuMzI0LDEuOTA5Yy0wLjQyOS0wLjQyOS0wLjQyOS0xLjE0MywwLTEuNTg3YzAuNDQ0LTAuNDI5LDEuMTQzLTAuNDI5LDEuNTg3LDBsOS41MjMsOS41MzkNCglsOS41MzktOS41MzljMC40MjktMC40MjksMS4xNDMtMC40MjksMS41NzEsMGMwLjQ0NCwwLjQ0NCwwLjQ0NCwxLjE1OSwwLDEuNTg3bC05LjUyMyw5LjUyNGw5LjUyMyw5LjUzOQ0KCWMwLjQ0NCwwLjQyOSwwLjQ0NCwxLjE0MywwLDEuNTg3Yy0wLjQyOSwwLjQyOS0xLjE0MywwLjQyOS0xLjU3MSwwbC05LjUzOS05LjUzOWwtOS41MjMsOS41MzljLTAuNDQ0LDAuNDI5LTEuMTQzLDAuNDI5LTEuNTg3LDANCgljLTAuNDI5LTAuNDQ0LTAuNDI5LTEuMTU5LDAtMS41ODdsOS41MjMtOS41MzlMMC4zMjQsMS45MDl6Ii8+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8L3N2Zz4NCg==" /></div>
          </div>
          <div class="dz-upload">
            <div class="dz-uploading">${this.$t("uploadingFile")}</div>
            <div class="dz-progress">
              <div class="dz-current-progress" style="width:0.1%;"></div>
            </div>
          </div>
        </div>
        `,
      headers: { Authorization: `Bearer ${this.$store.state.auth.user.token}` },
    };
  }

  removePreview(file) {
    this.$refs.myDropzone.removeFile(file);
    this.uploadDisabled = !this.$refs.myDropzone?.getQueuedFiles().length;
    this.errors = this.errors.filter((error) => error.filename !== file.name);
  }
  processQueue() {
    this.loading = true;
    this.$refs.myDropzone.processQueue();
  }
  uploadSuccess(file) {
    if (this.onUpload) {
      this.onUpload();
    }
    this.removePreview(file);
  }
  uploadError(file, error, request) {
    if (file.previewElement) {
      file.previewElement.classList.remove("uploading");
    }
    this.$refs.myDropzone.processQueue();

    const fileError = {
      filename: file.name,
      validations: {},
    };
    if (request && request.status === 0) {
      fileError.validations.network = true;
      Sentry.captureException("Upload File Network Error: " + error);
    }

    if (!!error && !!error.validations) {
      const fileValidations = error.validations.find(
        (validation) => validation.field === "fileToUpload"
      );
      if (fileValidations) {
        fileValidations.validations.forEach((validation) => {
          switch (validation.validation) {
            case "Mimes":
              fileError.validations.mimes = validation.value;
              break;
            case "Max":
              fileError.validations.max = validation.value / 1000; // To convert B to MB
              break;
          }
        });
      }
    }
    this.errors.push(fileError);
  }
  finishQueue() {
    this.loading = false;

    if (!this.errors.length) {
      this.$emit("success");
    }

    this.uploadDisabled = !this.$refs.myDropzone?.getQueuedFiles().length;
  }
  thumbnail(file) {
    window.files = this.$refs.myDropzone;

    if (file.previewElement) {
      const cross = file.previewElement.querySelector(".dz-remove-mark");
      cross.addEventListener("click", () => {
        this.removePreview(file);
      });
      file.previewElement.classList.remove("dz-file-preview");
    }

    this.$nextTick(() => {
      this.uploadDisabled = !this.$refs.myDropzone?.getQueuedFiles().length;
    });
  }
  uploading(file) {
    if (file.previewElement) {
      file.previewElement.classList.add("uploading");
    }
  }
  handleProgress(file, progress) {
    if (file.previewElement) {
      const currentProgress = file.previewElement.querySelector(
        ".dz-current-progress"
      );
      currentProgress.style.width = `${progress}%`;
    }
  }
}
</script>

<template>
  <div>
    <dropzone
      id="customdropzone"
      ref="myDropzone"
      :options="dropzoneOptions"
      :include-styling="false"
      :use-custom-slot="true"
      @vdropzone-sending="uploading"
      @vdropzone-error="uploadError"
      @vdropzone-file-added="thumbnail"
      @vdropzone-success="uploadSuccess"
      @vdropzone-queue-complete="finishQueue"
      @vdropzone-upload-progress="handleProgress"
      @vdropzone-drag-start="over = true"
      @vdropzone-drag-over="over = true"
      @vdropzone-drag-end="over = false"
      @vdropzone-drop="over = false"
    >
      <div
        class="flex flex-col items-center justify-center p-4 w-full"
        :class="{ 'bg-transparentBlue': over }"
      >
        <h3 class="font-medium text-center">
          {{ $t("selectFile") }}
        </h3>
        <div v-if="$mqd" class="text-sm">{{ $t("orClickHere") }}</div>
        <div class="text-sm">
          {{ $t("supportedFormats", { mimes: mimesMessage }) }}
        </div>
      </div>
    </dropzone>
    <HiboQuote
      v-for="(error, index) in errors"
      :key="`error-${index}`"
      class="mt-3"
    >
      <div>
        <p>{{ $t("anErrorHasOccurredWithFile", { file: error.filename }) }}</p>
        <p v-show="!!error.validations.mimes" class="text-grey mt-1">
          {{ $t("supportedFormats", { mimes: error.validations.mimes }) }}
        </p>
        <p v-show="!!error.validations.max" class="text-grey mt-1">
          {{ $t("maxFileSize", { size: error.validations.max }) }}
        </p>
        <p v-show="!!error.validations.network" class="text-grey mt-1">
          {{ $t("makeSureFileOnYourDeviceOrUploadItFromComputer") }}
          {{ $t("sorryForTheDisturbances") }}
        </p>
      </div>
    </HiboQuote>
    <HiboButton
      class="w-full mt-10"
      :disabled="uploadDisabled"
      :class="{ 'is-loading': loading }"
      @click="processQueue"
    >
      {{ $t("save") }}
    </HiboButton>
  </div>
</template>

<style lang="scss">
#customdropzone {
  color: #a2adc1;

  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;

  .dz-message {
    border: 1px dashed #dde6ee;

    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dz-preview {
    display: inline-block;
    margin-bottom: 10px;
  }
  .dz-error {
    border: 1px solid orangered;
    border-radius: 4px;
  }

  .dz-remove-mark {
    margin-left: 24px;
    cursor: pointer;
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .dz-upload {
    display: none;
  }

  .uploading {
    display: flex;
    align-items: center;
    width: 100%;

    .dz-remove-mark {
      display: none;
    }
    .dz-upload {
      width: 100%;
      display: block;
      margin-left: 5px;

      .dz-progress {
        border-radius: 4px;
        overflow: hidden;

        .dz-current-progress {
          max-width: 100%;
          height: 0.5rem;
        }
      }
    }
  }

  .dz-details {
    display: flex;
    justify-content: space-between;
    min-width: 160px;
    border: 1px solid #dde6ee;
    padding: 4px 10px;
    border-radius: 4px;
    .dz-filename {
      span {
        word-break: break-word;
      }
    }
  }
}
</style>
