var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.steps),function(step,index){return _c('div',{key:index,staticClass:"flex items-start relative",class:{
      'border-persimmon': step.completed,
      'border-edgeWater': !step.completed,
      'border-l-2': !_vm.isLastStep(index),
    },style:(_vm.isLastStep(index) ? 'margin-left: 12px' : 'margin-left: 10px')},[_c('div',{staticClass:"flex items-center justify-center rounded-full absolute p-1",class:{
        'bg-persimmon': step.completed,
        'bg-snowDrift border border-persimmon': _vm.isCurrentStep(index),
        'bg-snowDrift border-2 border-edgeWater h-20 w-20':
          !_vm.isCurrentStep(index) && !step.completed,
      },style:({ left: (!_vm.isCurrentStep(index) ? -11 : -10) + 'px' })},[(step.completed)?_c('HiboSvg',{attrs:{"svg":"icons/check","svg-class":"text-white w-15"}}):_vm._e(),(_vm.isCurrentStep(index))?_c('div',{staticClass:"bg-persimmon w-10 h-10 rounded-full"}):_vm._e()],1),_c('div',{staticClass:"pl-15",class:{ 'pb-20': !_vm.isLastStep(index) }},[_c('p',{staticClass:"text-sm pb-5 pl-5",class:{
          'text-smaltBlue': step.completed,
          'font-bold text-midnight': _vm.isCurrentStep(index),
          'font-medium text-smaltBlue opacity-50':
            !step.completed && !_vm.isCurrentStep(index),
        }},[(_vm.numbered)?[_vm._v(_vm._s(index + 1)+". ")]:_vm._e(),_vm._v(" "+_vm._s(_vm.$t(_vm.getStepLabel(index, step)))+" ")],2),(
          step.adressMsg && _vm.isCurrentStep(index) && _vm.isNextToLastStep(index)
        )?_c('span',{staticClass:"text-smaltBlue text-sm"},[_c('div',{staticClass:"border border-edgeWater rounded-md p-10"},[_c('p',{staticClass:"text-midnight font-medium pb-5"},[_vm._v(" "+_vm._s(_vm.getDateAndTime(step.futureDate))+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t(step.adressMsg)))])])]):_vm._e(),(
          step.infoMsg &&
          _vm.isCurrentStep(index) &&
          !step.lostDate &&
          !_vm.isLastStep(index) &&
          !_vm.isNextToLastStep(index)
        )?_c('span',{staticClass:"text-xs text-smaltBlue pl-5"},[_vm._v(" "+_vm._s(_vm.$t(step.infoMsg))+" ")]):_vm._e(),(step.completed)?_c('span',{staticClass:"text-xs text-mountainMeadow pl-5"},[_vm._v(" "+_vm._s(_vm.$tc(step.completedMsg, 1))+" "),(step.completedDate)?[_vm._v(" "+_vm._s(_vm.getDate(step.completedDate))+" ")]:_vm._e()],2):_vm._e(),(!step.completed && step.lostDate)?_c('span',{staticClass:"text-xs text-persimmon"},[_c('div',{staticClass:"p-5 pb-10"},[_vm._v(" "+_vm._s(_vm.lostTitle(step))+" "),(step.lostDate)?[_vm._v(" "+_vm._s(_vm.getDate(step.lostDate))+" ")]:_vm._e()],2),_c('div',{staticClass:"w-full rounded-md flex items-center bg-appMainLight"},[_c('HiboSvg',{staticClass:"p-5",attrs:{"svg":"icons/warn","width":"20"}}),_c('span',{staticClass:"p-5"},[_vm._v(_vm._s(_vm.$t(step.lostMsg)))])],1)]):_vm._e()])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }