
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import DocumentsWidget from "@/components/widgets/DocumentsWidget.vue";
import { IDocument } from "@/models/Documents";
import { IMortgageData } from "@/models/Mortgages";

@Component({ components: { DocumentsWidget } })
export default class MortgagesDocuments extends Vue {
  @Prop({ type: Object, required: true })
  protected mortgage!: IMortgageData;

  protected mortgageDocuments: IDocument[] = [];

  protected getMortgagesFiles() {
    this.$store
      .dispatch("mortgages/getMortgageFiles", this.mortgage.id)
      .then((result) => (this.mortgageDocuments = result));
  }

  @Watch("mortgage", { immediate: true })
  handleMortgage() {
    this.getMortgagesFiles();
  }
}
