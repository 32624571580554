
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { ITh } from "@/models/Global";

@Component
export default class CustomTable extends Vue {
  @Prop({ type: Array, required: true })
  protected thConfig!: ITh[];

  @Prop({ type: String, default: "left" })
  protected thAlign?: string;

  @Prop({ type: Boolean, default: false })
  protected fixedFirstCol?: boolean;

  @Emit("sort")
  sortList(columnId: string): string {
    return columnId;
  }
}
