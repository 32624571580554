
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class HiboQuote extends Vue {
  @Prop({ type: Number, default: 4 })
  protected barWidth?: number;

  @Prop({ type: Boolean, default: false })
  protected rounded?: boolean;
}
