
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { ITh, IPagination } from "@/models/Global";
import HiboLoader from "@/components/ui/HiboLoader.vue";
import CustomTable from "@/components/common/CustomTable.vue";
import MortgagesDocuments from "@/components/mortgages/MortgagesDocuments.vue";
import MortgagesStatus from "@/components/mortgages/MortgagesStatus.vue";

@Component({
  components: {
    HiboLoader,
    CustomTable,

    MortgagesDocuments,
    MortgagesStatus,
  },
})
export default class CustomTableWrapper extends Vue {
  @Prop({ type: Boolean, default: false })
  protected loading?: boolean;

  @Prop({ type: Number, required: true })
  protected length!: number;

  @Prop({ type: Object, required: true })
  protected pagination!: IPagination;

  @Prop({ type: Array, required: true })
  protected thConfig!: ITh[];

  protected get paginationFrom(): number {
    return this.pagination &&
      this.pagination.page &&
      this.pagination.itemsPerPage
      ? (this.pagination.page - 1) * this.pagination.itemsPerPage + 1
      : 0;
  }

  protected get paginationTo(): number {
    if (
      this.pagination &&
      this.pagination.page &&
      this.pagination.itemsPerPage
    ) {
      return this.pagination.totalItems &&
        this.paginationFrom + this.pagination.itemsPerPage >
          this.pagination.totalItems
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage * this.pagination.page;
    } else return 0;
  }

  @Emit("sort")
  sortList(columnId: string): string {
    return columnId;
  }

  @Emit("paginate")
  paginate(dir: string): string {
    return dir;
  }
}
