
import {
  Component,
  Vue,
  Prop,
  Watch,
  Emit,
  Inject,
} from "vue-property-decorator";
import { IDocument, IDocumentTranslations } from "@/models/Documents";
import {
  MORTGAGE_DOC_LOKALISE_KEYS,
  AFTER_SALES_DOC_LOKALISE_KEYS,
} from "@/constants";
import HiboFileUploaderModal from "@/components/ui/HiboFileUploaderModal.vue";
import { IMortgageStatus } from "@/models/Mortgages";
import { BUSINESS } from "@/constants";

@Component({
  components: { HiboFileUploaderModal },
})
export default class DocumentsWidget extends Vue {
  @Inject() readonly curr_business!: string;

  @Prop({ type: [Object, String], required: true })
  protected operationStatus!: IMortgageStatus | string;

  @Prop({ type: Number, required: true })
  protected operationId!: number;

  @Prop({ type: Object, required: true })
  protected document!: IDocument;

  protected loading = true;
  protected uploadUrl = "";
  protected uploaderModal = false;

  protected getTranslationsById(id: number): IDocumentTranslations | null {
    return (
      MORTGAGE_DOC_LOKALISE_KEYS.find((translate) => translate.id === id) ||
      null
    );
  }

  protected getTranslationsByCode(type: string): IDocumentTranslations | null {
    return (
      AFTER_SALES_DOC_LOKALISE_KEYS.find(
        (translate) => translate.code === type
      ) || null
    );
  }

  protected setDocumentInitialData() {
    if (this.curr_business === BUSINESS.mortgages) {
      if (this.document.documentTypeId)
        this.document.translations =
          this.getTranslationsById(this.document.documentTypeId) || null;
    } else {
      if (this.document.type)
        this.document.translations =
          this.getTranslationsByCode(this.document.type) || null;
    }

    this.$store
      .dispatch(`${this.curr_business}/getUploadDocUrl`, {
        operationId: this.operationId,
        documentId: this.document.id,
      })
      .then((result) => (this.uploadUrl = result));
    this.loading = false;
  }

  @Emit("uploaded")
  protected docsUploaded() {
    this.uploaderModal = false;
  }

  @Watch("document", { immediate: true })
  documentUpdate() {
    this.setDocumentInitialData();
  }
}
